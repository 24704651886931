.mr-2 {
  margin-right: 15px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.text-right {
  text-align: right;
}

.medium-btn {
  min-width: 80px;
}

$gray-light: (
  100: #ffffff,
  200: #eff2f7,
  300: #e6edff,
  400: #ced4da,
  500: #adb5bd,
  600: #6c757d,
  700: #495057,
  800: #343a40,
  900: #212529,
  1000: #2a3042,
  1001: #3d79f7,
  purple: linear-gradient(-90deg, #9951ff 0%, #6900ff 100%),
  blue: linear-gradient(-90deg, #0681c6 0%, #000de9 100%),
  orange: linear-gradient(-90deg, #fcab01 0%, #ff4c39 100%),
  pink: linear-gradient(-90deg, #ee5380 0%, #ca2374 100%),
  green: linear-gradient(-90deg, #48cf63 0%, #389a09 100%),
  singlepink: #ca2374,
  singleorange: #dd4c39,
  singleblue: #000de9,
  singlepurple: #6900ff,
  singlegreen: #389a09,
);

$gray-dark: (
  100: #ffffff,
  200: #3a4560,
  300: #32394e,
  400: var(--gray-700),
  500: #bfc8e2,
  600: #c3cbe4,
  700: #f6f6f6,
  800: #eff2f7,
  900: #f8f9fa,
  1000: #a6b0cf,
  1001: #ffffff,
  purple: linear-gradient(-90deg, #9951ff 0%, #6900ff 100%),
  blue: linear-gradient(-90deg, #0681c6 0%, #000de9 100%),
  orange: linear-gradient(-90deg, #fcab01 0%, #ff4c39 100%),
  pink: linear-gradient(-90deg, #ee5380 0%, #ca2374 100%),
  green: linear-gradient(-90deg, #48cf63 0%, #389a09 100%),
  singlepink: #ca2374,
  singleorange: #dd4c39,
  singleblue: #000de9,
  singlepurple: #6900ff,
  singlegreen: #389a09,
);

@mixin set-gray-colors($mode) {
  $gray-map: if($mode =="dark",
      $gray-dark,
      $gray-light ); // Select the appropriate map

  @each $key, $value in $gray-map {
    --gray-#{$key}: #{$value};
  }
}

[data-layout-mode="dark"] {
  @include set-gray-colors(dark);
}

[data-layout-mode="light"],
:root {
  @include set-gray-colors(light);
}

.header-item {
  height: auto;
}

body .vertical-menu {
  background: var(--gray-100);
}

#sidebar-menu ul li a {
  color: var(--gray-1000);
}

#sidebar-menu ul li a:hover {
  color: #ffffff;

  i {
    color: #ffffff;
  }
}

#sidebar-menu ul li ul.sub-menu li a {
  color: var(--gray-1000);
}

#sidebar-menu ul li ul.sub-menu li a:hover {
  color: #ffffff;
}

#sidebar-menu ul li a i {
  color: var(--gray-1000);
}

.form-control {
  border: 1px solid #51576b;
  background-color: transparent !important;
}

.sidebar-bottom {
  position: absolute;
  bottom: 10px;
  text-align: center;
  left: 0;
  right: 0;

  img {
    max-width: 120px;
  }

  p {
    margin-bottom: 0px;
  }
}

.form-control::-webkit-input-placeholder {
  /* Edge */
  color: #f6f6f6;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #f6f6f6;
}

.form-control::placeholder {
  color: #f6f6f6;
}

select {
  color: #f6f6f6 !important;
}

select option {
  color: #f6f6f6;
}

select option:first-child {
  color: #f6f6f6;
}

.navbar-header {
  height: 50px;
}

.page-content {
  padding: calc(50px + 12px) calc(12px * 0.75) 0px calc(12px * 0.75);
}

.logo {
  line-height: 50px;
}

.vertical-collpsed .vertical-menu {
  padding-top: 50px;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a {
  position: relative;
  width: calc(190px + 70px);
  color: #3d79f7;
  background-color: #2e3548;
  transition: none;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul ul {
  padding: 5px 0;
  z-index: 9999;
  display: none;
  background-color: #2e3548;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul ul li a {
  color: #eff2f7 !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul ul li:hover>a {
  color: #3d79f7 !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul li.active>a i {
  color: #3d79f7 !important;
}

.vertical-collpsed .sidebar-bottom {
  display: block;

  .logo-box {
    display: none;
  }

  .logo-box.small-logo-box {
    display: block;
    margin-top: 10px;

    img {
      width: 30px;
    }
  }

  p {
    line-height: 1.2;
  }
}

#sidebar-menu {
  .metismenu {
    height: calc(100vh - 180px);
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.padding-top {
  padding-top: 10px;

  #page-header-user-dropdown {
    padding-top: 0px;
  }
}

.loader-box {
  position: fixed;
  left: 250px;
  right: 0;
  text-align: center;
  top: 50px;
  background: #181b23;
  z-index: 99;
  opacity: 0.8;
  height: calc(100vh - 50px);

  .loader {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0px;
    text-align: center;
    margin: auto;
    width: 55px;
    height: 55px;
    background-repeat: no-repeat;
    background-image: linear-gradient(#3d79f7 50px, transparent 0),
      linear-gradient(#0ac074 50px, transparent 0),
      linear-gradient(#3d79f7 50px, transparent 0),
      linear-gradient(#0ac074 50px, transparent 0),
      linear-gradient(#3d79f7 50px, transparent 0),
      linear-gradient(#0ac074 50px, transparent 0);
    background-size: 5px 40px;
    background-position: 0px center, 10px center, 20px center, 30px center,
      40px center, 50px center;
    animation: spikeUp 1s linear infinite alternate;
    display: block;
  }

  @keyframes spikeUp {
    0% {
      background-size: 5px 40px;
    }

    16% {
      background-size: 5px 55px, 5px 40px, 5px 40px, 5px 40px, 5px 40px,
        5px 40px;
    }

    33% {
      background-size: 5px 40px, 5px 55px, 5px 40px, 5px 40px, 5px 40px,
        5px 40px;
    }

    50% {
      background-size: 5px 40px, 5px 40px, 5px 55px, 5px 40px, 5px 40px,
        5px 40px;
    }

    66% {
      background-size: 5px 40px, 5px 40px, 5px 40px, 5px 55px, 5px 40px,
        5px 40px;
    }

    83% {
      background-size: 5px 40px, 5px 40px, 5px 40px, 5px 40px, 5px 55px,
        5px 40px;
    }

    100% {
      background-size: 5px 40px, 5px 40px, 5px 40px, 5px 40px, 5px 40px,
        5px 55px;
    }
  }
}

.vertical-collpsed .loader-box {
  left: 70px;
}

// .scroll-bar {
//   max-height: calc(100vh - 115px);
//   overflow-y: hidden;
//   scrollbar-width: auto;
// }

// .scroll-bar:hover {
//   scrollbar-width: thin;
// }

@media (max-width: 992px) {
  .vertical-collpsed {
    .vertical-menu {
      display: none !important;
    }
  }

  .vertical-menu {
    top: 50px;
  }

  .vertical-collpsed .vertical-menu {
    padding-top: 0px;
  }

  .vertical-collpsed .loader-box {
    left: 0;
  }
}

.right-panel {
  width: 500px;
  position: fixed;
  right: 0px;
  top: 99px;
  background: #222736;
  z-index: 98;
  height: calc(100vh - 110px);
  padding: 1rem;
  border: 2px solid #373e4e;

  .close-btn {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.table> :not(caption)>*>* {
  padding: 0.4rem 0.4rem;
}

.top-header {
  th {
    background: #454d64 !important;
  }
}

.nav-width {
  text-align: right;

  .nav-tabs-custom {
    width: 380px;
  }
}

.round-btn {
  // background: #222736;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 6px 5px !important;
  font-size: 18px;
}

.form-check-input {
  border: 1px solid #686e83;
}

.filter-box {
  position: absolute;
  right: 112px;
  top: 40px;
  width: 460px;
  padding: 16px;
  background-color: #222736;
  z-index: 10;
  border: 1px solid #454d64;
  border-radius: 4px;

  .form-label {
    display: block;
  }

  .form-control {
    width: 100% !important;
    margin-left: 0px !important;
  }

  .ql-snow.ql-toolbar button {
    stroke: #ffff !important;
  }

  .btn-outline-light {
    border-color: #454d64 !important;
  }

  .close-btn {
    position: absolute;
    right: 0;
    top: 0;
  }
}

//.main-tabcontent {
//height: calc(100vh - 62px);
//}

.setting-left-box {
  width: 280px !important;

  // height: calc(100vh - 140px) !important;
  .nav-item {
    display: block;
    width: 100%;
  }

  .nav {
    max-height: calc(100vh - 160px) !important;
    overflow-y: auto !important;
    background: #2f436c;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.close-left .setting-left-box {
  left: -296px !important;
}

.setting-right-box {
  padding-left: 280px !important;
  //height: calc(100vh - 140px) !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.close-left .setting-right-box {
  padding-left: 0px !important;
}

.close-left .setting-right-box .page-title-box h4 {
  padding-left: 20px !important;
}

.close-left .setting-left-box .tab-btn {
  left: 100% !important;
}

.box-height {
  text-align: center;

  ul {
    height: 300px;
    text-align: left;
    // list-style: none;

    li {
      margin-bottom: 8px;
      position: relative;
    }
  }

  .btn {
    margin: 0 auto;
  }
}

.list-activity {
  list-style: none;
  padding-left: 0;
}

.setting-right-box .location-tabs {
  position: absolute;
  top: 0px;
  right: 0;
}

.css-2rshpx-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
  color: #5f6579 !important;
  cursor: crosshair;
}

.css-2rshpx-MuiButtonBase-root-MuiIconButton-root.Mui-disabled:hover {
  cursor: crosshair;
}

// .css-1ka5eyc-MuiPaper-root-MuiMenu-paper-MuiPopover-paper {
//   background-color: #222736 !important;
//   color: rgb(165 175 206) !important;
// }

.MuiMenu-paper{
  background-color: #fff !important;
    color: rgb(108 116 140) !important;
}

.css-uob957-MuiListSubheader-root {
  color: rgb(150 159 189) !important;
  background-color: none !important;
}

.dark-theme .css-uob957-MuiListSubheader-root {
  background-color: transparent !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: rgb(114 123 148) !important;
}

.css-ittuaa-MuiInputAdornment-root {
  color: rgb(165 175 206) !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  font-size: inherit !important;
}

.css-1ua80n0-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: inherit !important;
  color: rgb(165 175 206) !important;
  height: 1.125em !important;
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  color: rgb(166 176 207) !important;
}

.css-1orbl2q-MuiDataGrid-root .MuiDataGrid-cell.MuiDataGrid-cell--editing {
  background-color: #454d60 !important;
  color: rgb(166 176 207) !important;
}

.setting-right-box .page-title-box h4,
.setting-right-box .page-title-box .h4 {
  padding-left: 40px;
}

.add-row-btn {
  position: absolute;
  right: 12px;
  top: 0;
}

select option {
  margin: 40px;
  background: #2a3042 !important;
  color: rgb(166 176 207) !important;
  padding: 15px;
  height: 30px;
  // text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root {
  color: var(--gray-1000) !important;
}

.css-1ps6pg7-MuiPaper-root {
  background-color: #32394b !important;
  color: var(--gray-1000) !important;
}

.css-cveggr-MuiListItemIcon-root {
  color: var(--gray-1000) !important;
}

.css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
  color: var(--gray-1000) !important;
}

.css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
  color: #666f89 !important;
}

.fixed-right {
  position: absolute;
  right: 10px;
  top: 0px;

  .form-control {
    width: 200px;
    display: inline-block;
    margin-right: 20px;
  }
}

.notification-menu {
  .row {
    width: 350px;
  }

  ul {
    padding-left: 10px;
    max-height: 300px;
    overflow-y: auto;
  }

  li {
    list-style: none;
    margin-bottom: 10px;
  }
}

select {
  background-image: linear-gradient(45deg, transparent 50%, #c3cbe4 50%),
    linear-gradient(135deg, #c3cbe4 50%, transparent 50%),
    linear-gradient(to right, #c3cbe4, #c3cbe4);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

select:focus {
  background-image: linear-gradient(45deg, #3d79f7 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, #3d79f7 50%),
    linear-gradient(to right, #c3cbe4, #c3cbe4);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border-color: #3d79f7;
  outline: 0;
}

.profile-page-bg {
  background: rgb(0 13 233) !important;
    text-align: center;
    padding: 40px 20px;
    width: 300px;
    position: relative;
    display: inline-block;
    color: #fff;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;

  .profile-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 1px solid var(--gray-1000);
    margin: auto;
    display: block;
    background-color: #3d79f7;
  }

  .line {
    h3 {
      margin-top: 20px;
    }

    label {
      color: #ffffff;
      display: block;

      text-align: left;
      padding: 0px 10px;
      margin-bottom: 20px;
    }
  }

  .gender-bage {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.profile-detail {
  width: calc(100% - 300px);
  display: inline-block;
  vertical-align: top;
  padding: 40px;

  p {
    .count {
      background: #010ee9;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    font-size: 16px;
    margin-right: 15px;
    font-weight: 600;
    padding-top: 3px;
    vertical-align: middle;

    }
  }

  p {
    label {
      margin-bottom: 0px;
    }
  }
}

.report-text-right {
  text-align: right;
  margin-bottom: 10px;

  .form-control {
    width: 200px;
    display: inline-block;
    margin-right: 10px;
  }

  .dropdown {
    display: inline-block;
  }
}

.report-table {

  th,
  td {
    text-align: center;
    vertical-align: middle;
  }
}

.instruction-search {
  .form-control {
    width: 200px;
  }
}

.breadcrumb-item.active {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px !important;
}

.css-1b74o31-MuiInputBase-root-MuiDataGrid-editInputCell input {
  color: #ffffff !important;
}

.address-line .d1 {
  width: 60% !important;
}

.address-line .d1:last-child {
  width: 40% !important;
}

.page-title-box {
  position: relative;
}

.Toastify__toast-theme--dark {
  background: #2a3042 !important;
}

.master-title {
  margin-bottom: 10px;
}

.master-search {
  position: absolute;
  right: 0;
  top: -45px;

  .form-control {
    display: inline-block;
    vertical-align: top;
    width: 230px;
  }

  .btn {
    display: inline-block;
    vertical-align: middle;
  }
}

.grid-height {
  height: calc(100vh - 200px);
  width: 100%;
}

.css-i4bv87-MuiSvgIcon-root {
  width: 0.8em !important;
  height: 0.8em !important;
}

.dashboard-small-box .avatar-sm {
  width: 80px !important;
  height: 80px !important;
}

.dashboard-small-box .avatar-title {
  border-radius: 7px;
  background-color: var(--gray-300);
  font-size: 36px;
  color: var(--gray-1001);
}

.inner-col-box {
  background: var(--gray-300);
  border-radius: 8px;
  padding: 24px 15px;
  border-left: 6px solid #0ac074;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.inner-col-box p {
  margin-bottom: 0px !important;
  font-size: 18px;
  font-weight: 500;
}

.inner-col-box h4 {
  font-size: 22px;
  margin-bottom: 0px !important;
  font-weight: 600;
}

.inner-col-box:nth-child(2) {
  border-left: 6px solid #1e81ce;
}

.dashboard-filter {
  right: 14px;
}

.css-j3dwud-MuiDataGrid-root .MuiDataGrid-cell.MuiDataGrid-cell--editing {
  background-color: transparent !important;
}

.css-h66mze-MuiDataGrid-root .MuiDataGrid-cell.MuiDataGrid-cell--editing {
  background-color: transparent !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--gray-1000) !important;
}

.custom-card {
  background-color: #3a4560;
  /* Replace with your desired dark color */
  color: white;
  /* Text color */
}

.list-inside {
  list-style: inside !important;
}

.card-common-dashboard {
  height: 310px;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.MuiButtonBase-root.Mui-disabled {
  color: rgb(192 192 192 / 76%) !important;
}

.MuiTablePagination-selectLabel {
  color: #f6f6f6 !important;
}

.MuiSelect-icon {
  color: #f6f6f6 !important;
}

.MuiTablePagination-displayedRows {
  color: #f6f6f6 !important;
}

.MuiSelect-select {
  color: #f6f6f6 !important;
}

/*Custome Scrollbar*/
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 3px grey;
  background: #d7d2d2;
  border: 1px solid #dddddd;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #171b27;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #32394e;
}

.nav-tabs-custom {
  border: none;
}

.large-card .patient-detail .address-line .d1 {
  width: 50% !important;
}

.setting-tab-content {
  .master-search {
    position: absolute;
    right: 230px;
    top: -45px;
  }
}

.MuiFormControl-root {
  svg {
    color: var(--gray-singleblue);
  }
}

.MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
  background-color: transparent !important;
  color: rgb(165 175 206) !important;
}

.MuiTablePagination-toolbar {
  display: flex !important;
  align-items: last baseline !important;
}

.MuiDataGrid-editInputCell {
  color: rgb(165 175 206) !important;

  input {
    color: rgb(165 175 206) !important;
  }
}

.add-accordian-btn {
  display: flex;
  justify-content: end;
  margin-bottom: 15px;
}

.multi-select-control {
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 6px 14px !important;
  }
}

.all-master-page {
  .setting-right-box {
    overflow: inherit !important;

    .grid-height {
      .MuiDataGrid-virtualScroller {
        overflow: hidden !important;
        max-height: calc(100vh - 100px) !important;
      }
    }
  }
}

.user-role-list-content {
  h5 {
    height: 30px;
  }
}

.ql-snow .ql-stroke {
  stroke: #c3cbe4 !important;
}

.ql-snow .ql-picker {
  color: #c3cbe4 !important;
}

.ql-picker-options {
  background-color: #444 !important;
}

.ql-toolbar.ql-snow {
  border: 1px solid #51576b !important;
  background-color: transparent !important;
  border-radius: 4px !important;
}

.ql-container.ql-snow {
  border: 1px solid #51576b !important;
  background-color: transparent !important;
  border-radius: 4px !important;
}

.ql-editor {
  resize: vertical !important;
  min-height: 100px !important;
}

.richtext-p-tag p {
  display: block !important;
}

.metismenu li.active a>i {
  color: #3d79f7 !important;
}

.margin-top-20 {
  margin-top: 20px;
}

.edit-icon-container {
  position: relative;
}

.edit-icon-circle {
  position: absolute;
  top: -40px;
  right: 0;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #3d79f7 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.edit-icon-button {
  color: white !important;
  position: relative;
  z-index: 2;
}

.fixed-table-head {
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 400px;

  thead {
    th {
      position: sticky;
      top: 0;
    }
  }
}

.fixed-table-top {
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  thead {
    th {
      position: sticky;
      top: 0;
    }
  }
}

.quill {
  .ql-toolbar {
    background: #202736 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .ql-container {
    border-top: none !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }
}

.card-common-dashboard {
  overflow: clip;

  .card-body {
    padding: 0;
    margin: 16px;
    overflow: auto;
    scrollbar-width: none;
  }
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #fff !important;
}

.sidebar-enable {
  .small-logo-box {
    display: none;
  }
}

.userrole-box {
  h5 {
    height: 32px;
  }
}

.user-form-detail-panel {
  overflow-y: unset !important;

  .tab-content,
  .form-common-scroll {
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: calc(100vh - 200px);
  }

  .form-common-scroll {
    height: calc(100vh - 160px);
  }
}

.user-role-list-content {
  // height: calc(100vh - 140px);
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.btn-coman-save {
  position: fixed;
  right: 255px;
  top: 192px;
}

.btn-end-coman-save {
  position: fixed;
  right: 25px;
  top: 125px;
}

.table-list {
  td {
    vertical-align: middle;
  }

  .left-logo {
    vertical-align: middle;
  }

  .right-detail {
    vertical-align: middle;
  }
}

//.main-table-con {max-height: calc(100vh - 200px);}

.age-range-top-box {
  .age-tange-rignt-box {
    display: inline-block !important;
    text-align: right;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.admission-card {
  .setting-right-box {
    .tab-content {
      .MuiDataGrid-virtualScroller {
        max-height: calc(100vh - 100px) !important;
      }
    }
  }
}

.light-theme {
  .bg-primary.bg-soft {
    background: #2f33bf !important;

    h4,
    p,
    h5 {
      color: #ffffff;
    }
  }

  .patient-dashboard {
    .d1 {
      i {
        color: var(--gray-1001);
      }
    }
  }

  .admission-card {
    .left-box {
      background: #2f33bf !important;

      ul {
        li {
          a {
            color: #ffffff !important;
          }
        }
      }

      .nav {
        background: #2f33bf !important;
      }
    }

    .right-panel {
      h4 {
        color: #283042;
      }
    }

    .tab-btn {
      background-color: #2f33bf !important;
    }
  }

  .dashboard-small-box {
    .avatar-title {
      background-color: #2a3042;

      i {
        color: #ffffff;
      }
    }
  }

  .card-common-dashboard {
    .inner-col-box {
      background: #2a3042;

      p,
      h4 {
        color: #ffffff;
      }
    }
  }

  table {
    thead {
      --bs-table-bg: var(--gray-singleblue) !important;
      --bs-table-color: #ffffff;
    }
  }

  .address-line {
    .d1 {
      label {
        color: #ffffff;
      }
    }
  }

  .MuiSelect-select {
    color: #6b6f81 !important;
  }

  .MuiSelect-icon,
  .MuiButtonBase-root.Mui-disabled,
  .MuiTablePagination-displayedRows {
    color: #51545e !important;
  }

  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell,
  .MuiTablePagination-selectLabel {
    color: #51545e !important;
  }

  .btn-outline-light {
    --bs-btn-color: var(--gray-100);
    --bs-btn-border-color: var(--gray-singleblue);
    background-color: var(--gray-singleblue);
    border-radius: 8px;

    &:hover {
      --bs-btn-color: var(--gray-singleblue) !important;
      border-color: var(--gray-singleblue) !important;
      background-color: transparent;
      color: var(--gray-singleblue);
    }
  }

  .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }

  .filter-box {
    background-color: var(--gray-100);
    box-shadow: 0px 10px 50px rgba(31, 30, 30, 0.2);
    border-color: #e3e3e3;
  }

  .form-control::placeholder,
  select {
    color: #495057 !important;
  }

  select {
    option {
      background: #e3eaf9 !important;
      color: #495057 !important;
    }
  }

  table {
    tbody {
      tr {
        border-color: #cbcbcb !important;
      }
    }
  }

  .MuiDataGrid-columnHeaderTitleContainer {
    color: #ffffff !important;
  }

  .dropdown-menu,
  form .right-panel {
    background-color: #f9f9f9;
  }

  .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
    color: #495057 !important;
  }

  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    -webkit-text-fill-color: #495057 !important;
  }

  .MuiDataGrid-root .MuiDataGrid-withBorderColor {
    border-color: #cbcbcb !important;
  }

  .structure-box .inner-con-box .MuiDataGrid-autoHeight,
  .MuiDataGrid-withBorderColor {
    border-color: #cbcbcb !important;
  }

  .nav-tabs-custom {
    .nav-item {
      .nav-link {
        font-weight: 500;

        span {
          color: #495057 !important;
        }

        i {
          color: rgb(61, 121, 247) !important;
        }
      }
    }
  }

  /*#sidebar-menu {
    ul {
      li {
        a {
          &:hover {
            color: #3d79f7 !important;
 
            i {
              color: #3d79f7 !important;
              ;
            }
          }
 
        }
      }
    }
  }*/
}

.admission-card {
  .setting-right-box {
    .tab-content {
      .MuiDataGrid-virtualScroller {
        max-height: calc(100vh - 100px) !important;
      }
    }
  }
}

.hourly-data-box {
  .MuiDataGrid-footerContainer {
    display: none !important;
  }
}

.right-5 {
  position: absolute;
  right: 5px;
}

.main-content {
  .page-content {
    padding: 50px 0 0;

    .container-fluid {
      padding: 15px 15px 15px;
    }
  }
}

.Mui-error {
  .MuiOutlinedInput-notchedOutline {
    border-color: #51576b !important;
  }
}

.patient-profile-box .overview-main .inner-box .inner-con-box div p {
  margin-bottom: 5px;
}

.admission-card .right-box .tab-content div p {
  margin-bottom: 5px;
}

.admission-card .right-box .tab-content div ol {
  margin-bottom: 5px;
}

.patient-profile-box .overview-main .inner-box .inner-con-box div ol {
  margin-bottom: 5px;
}

.admission-card .right-box .tab-content div ul {
  margin-bottom: 5px;
  // padding-bottom: 0px;
}

.patient-profile-box .overview-main .inner-box .inner-con-box div ul {
  margin-bottom: 5px;
  padding-bottom: 0px;
}

// Dashboard theame color changes
.top-box-1 {
  background: var(--gray-purple) !important;
}

.top-box-2 {
  background: var(--gray-blue) !important;
}

.top-box-3 {
  background: var(--gray-orange) !important;
}

.top-box-4 {
  background: var(--gray-pink) !important;
}

.chart-box-1 {
  background: var(--gray-blue) !important;
  border: none !important;
}

.chart-box-2 {
  background: var(--gray-green) !important;
  border: none !important;
}

.top-box-1 p,
.top-box-1 h5,
.top-box-2 p,
.top-box-2 h5,
.top-box-3 p,
.top-box-3 h5,
.top-box-4 p,
.top-box-4 h5 {
  color: var(--gray-100) !important;
}

.card {
  border-radius: 16px !important;
}

.light-theme .card-common-dashboard {
  border: 1px solid #e3e3e3 !important;
}

.top-box-1 .avatar-title,
.top-box-2 .avatar-title,
.top-box-3 .avatar-title,
.top-box-4 .avatar-title {
  background: var(--gray-100) !important;
}

.top-box-1 .avatar-title i {
  color: var(--gray-singlepurple) !important;
}

.top-box-2 .avatar-title i {
  color: var(--gray-singleblue) !important;
}

.top-box-3 .avatar-title i {
  color: var(--gray-singleorange) !important;
}

.top-box-4 .avatar-title i {
  color: var(--gray-singlepink) !important;
}

.top-box-row p {
  font-size: 18px !important;
  font-weight: 500 !important;
}

.top-box-row h5 {
  font-size: 22px !important;
  font-weight: 600 !important;
}

.top-box-row .dashboard-small-box {
  cursor: pointer;
  align-items: center;
}

tr:first-child th:first-child {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

tr:first-child th:last-child {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

.fixed-table-top thead th {
  padding: 10px !important;
}

.bg-transparent.border-bottom.card-header {
  padding: 0 !important;
}

.btn-info {
  background-color: var(--gray-singleblue);
  border-color: var(--gray-singleblue);
}

.btn-info:hover {
  background-color: transparent;
  color: var(--gray-singleblue);
  border-color: var(--gray-singleblue);
}

.filter-box {
  .btn {
    &:first-child {
      margin-right: 5px !important;
    }
  }
}

.css-igs3ac {
  border-color: rgb(227, 227, 227) !important;
}

.nav-tabs-custom {
  width: auto;
  background: #f5f6f7;
  padding: 4px;
  border-radius: 8px;
  gap: 8px;
  border: 1px solid #e3e3e3;
  align-items: center;
  height: auto;
  margin: 0;
}

.nav-tabs-custom .nav-item .nav-link.active {
  color: var(--gray-100);
  padding: 8px 20px;
  border-radius: 6px;
  border: none;
  background: var(--gray-singleblue);
}

.nav-tabs-custom .nav-item .nav-link {
  padding: 8px 16px;
}

.nav-tabs-custom .nav-item .nav-link::after {
  content: inherit;
}

.card-title {
  font-size: 18px;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li {
  padding: 0 10px;
  margin-bottom: 8px;
  width: auto;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li.active a {
  background: var(--gray-singleblue);
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a {
  min-height: auto;
  width: auto;
  border-radius: 8px;
  padding: 8px 14px;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a i {
  margin: 0;
  padding: 0;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a {
  background: var(--gray-singleblue);
  width: auto !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul li.active>a i,
.vertical-collpsed .vertical-menu #sidebar-menu>ul ul li:hover>a {
  color: var(--gray-100) !important;
  width: auto !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover>a i {
  color: var(--gray-100) !important;
}

#sidebar-menu ul li a i {
  min-width: auto !important;
}

.metismenu li {
  padding: 0 10px;
  margin-bottom: 8px;
}

#sidebar-menu ul li a {
  padding: 10px 16px;
  font-size: 14px;
  border-radius: 8px;
}

#sidebar-menu ul li.active a {
  background: var(--gray-singleblue);
  color: var(--gray-100);
  border-radius: 8px;
}

#sidebar-menu ul li a i {
  min-width: auto !important;
  margin: 0;
  padding: 0;
  margin-right: 10px !important;
}

#sidebar-menu ul li.active a i {
  color: var(--gray-100) !important;
}

#sidebar-menu ul li a:hover {
  background: var(--gray-singleblue);
  color: var(--gray-100);
  border-radius: 8px;
}

.light-theme .dropdown-menu .dropdown-item:hover {
  background: var(--gray-singleblue);
  color: var(--gray-100);
}

.light-theme .breadcrumb-item.active {
  color: var(--gray-singleblue);
}

.nav-tabs-custom .nav-item .nav-link.active span {
  color: var(--gray-100) !important;
}

.page-title-box .form-control:focus,
.light-theme .form-control:focus {
  border-color: var(--gray-singleblue) !important;
}

.light-theme .form-control {
  border-radius: 8px;
  background-color: #f7f7f7 !important;
  border-color: #e3e3e3 !important;
}

.light-theme {
  background: #f3f4f7 !important;
}

.light-theme .form-control.error {
  background: #fae2e2 !important;
  border-color: red !important;
}

.light-theme .form-control::placeholder,
.light-theme select {
  color: #8e8e8e !important;
}

.light-theme .MuiOutlinedInput-root {
  background: #f7f7f7 !important;
  border-radius: 8px !important;
  font-family: inherit !important;
}

.light-theme .Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #e3e3e3 !important;
}

.light-theme .MuiOutlinedInput-root .MuiOutlinedInput-input {
  color: #9f9f9f !important;
}

.light-theme .btn-primary {
  background: var(--gray-singleblue) !important;
  border-color: var(--gray-singleblue) !important;
}

.light-theme .btn-primary:hover {
  background: transparent !important;
  color: var(--gray-singleblue) !important;
}

.nav-tabs-custom.list-view-tab {
  background: transparent;
  padding: 0;
  gap: 0;
}

.nav-tabs-custom.list-view-tab .nav-item .nav-link {
  padding: 8px 14px;
}

.nav-tabs-custom.list-view-tab .nav-item .nav-link i {
  color: #495057 !important;
}

.nav-tabs-custom.list-view-tab .nav-item .nav-link.active i {
  color: var(--gray-100) !important;
}

.hospital-card-title .right-detail {
  text-transform: uppercase;
}

.light-theme .patient-search-box .form-control {
  background-color: var(--gray-100) !important;
}

.light-theme .hospital-card-title.card-title {
  display: flex;
  align-items: center;
}

.card-common-dashboard.overview-box .card-body {
  overflow: hidden;
}

.dark-theme {
  background: #020617 !important;
}

.dark-theme .card-common-dashboard {
  background: #0f172a !important;
  border: 1px solid #384458 !important;
}

.dark-theme .inner-col-box p {
  color: var(--gray-100);
}

.dark-theme .nav-tabs-custom .nav-item .nav-link.active {
  background: #2d3a4c;
  color: var(--gray-100);
}

.dark-theme .nav-tabs-custom .nav-item .nav-link {
  color: #a6b0cf;
}

.dark-theme .vertical-menu {
  background: #0f172a !important;
}

.dark-theme .vertical-menu #sidebar-menu>ul>li.active a {
  background: #2d3a4c !important;
}

.dark-theme #page-topbar {
  background: #0f172a !important;
}

.dark-theme .fixed-table-top thead th {
  border-bottom: transparent;
}

.dark-theme table thead {
  --bs-table-bg: #2d3a4c !important;
}

.dark-theme #sidebar-menu ul li a:hover {
  background: #2d3a4c !important;
}

.dark-theme .btn-outline-light {
  --bs-btn-color: var(--gray-100);
  --bs-btn-border-color: #2d3a4c;
  background-color: #2d3a4c;
  border-radius: 8px;
}

.dark-theme .btn-outline-light:hover {
  background-color: transparent;
  border-color: var(--gray-100);
}

.dark-theme .btn-outline-light:focus {
  box-shadow: none;
}

.dark-theme .nav-tabs-custom {
  background: #020617;
  border-color: #384458;
  overflow: hidden;
  --bs-nav-tabs-border-width: 0px;
}

.nav-tabs-custom {
  overflow: hidden;
  --bs-nav-tabs-border-width: 0px;
}

.dark-theme .form-control {
  border-radius: 8px;
}

.dark-theme .MuiMenu-paper {
  background-color: #0f172a !important;
  color: #fff !important;
}

//patient-card-css
.patient-card-box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.patient-card-box .patient-box {
  position: relative;
  padding: 16px;
  background: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 16px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);
  transition: 0.3s all ease-in-out;
}

.patient-card-box .patient-box:hover {
  border-color: #000de9;
}

.patient-card-box .patient-box .patient-title-box {
  display: flex;
  align-items: center;
  padding-bottom: 6px;
}

.patient-card-box .patient-box .patient-title-box svg {
  width: 34px;
  height: 34px;
  fill: #000de9;
}

.patient-card-box .patient-box .patient-title-box .icon-box {
  padding: 8px;
  background: #e0e2fc;
  border-radius: 8px;
  display: flex;
}

.patient-card-box .patient-box .patient-title-box h4 {
  padding-left: 12px;
  color: #000de9;
  font-size: 18px;
  margin: 0;
  text-transform: uppercase;
}

.patient-card-box .patient-box span {
  font-size: 12px;
  padding-bottom: 2px;
  color: #74788d;
  display: block;
}

.patient-card-box .patient-box p {
  font-size: 15px !important;
  color: #495057;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 260px;
  overflow: hidden;
}

.patient-card-box .patient-box .contact-box {
  display: flex;
  justify-content: space-between;
  padding-bottom: 6px;
}

.patient-card-box .patient-box .address-box {
  // height: 70px;
  overflow: hidden;
}

.patient-card-box .patient-box .status-box {
  margin-top: 6px;
  border-top: 1px solid #e3e3e3;
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.patient-card-box .patient-box .status-box .gender-box {
  padding: 6px 14px;
  background: #e0e2e3;
  border-radius: 8px;
}

.patient-card-box .patient-box .status-box .discharge-box {
  display: flex;
  gap: 8px;
  background: #ddead6;
  padding: 6px 14px;
  align-items: center;
  border-radius: 8px;
}

.patient-card-box .patient-box .status-box .discharge-box svg {
  width: 24px;
  height: 24px;
  display: flex;
  fill: #389a09;
}

.patient-card-box .patient-box .status-box .discharge-box p {
  color: #389a09;
}

.patient-card-box .patient-box .status-box .admitted-box {
  display: flex;
  gap: 8px;
  background: #e0e2fc;
  padding: 6px 14px;
  align-items: center;
  border-radius: 8px;
}

.patient-card-box .patient-box .status-box .admitted-box svg {
  width: 24px;
  height: 24px;
  display: flex;
  fill: #000de9;
}

.patient-card-box .patient-box .status-box .admitted-box p {
  color: #000de9;
}

.patient-card-box .patient-box .status-box .not-admitted-box {
  display: flex;
  gap: 8px;
  background: #e0e2e3;
  padding: 6px 14px;
  align-items: center;
  border-radius: 8px;
}

.patient-card-box .patient-box .status-box .not-admitted-box svg {
  width: 24px;
  height: 24px;
  display: flex;
  fill: #495057;
}

.patient-card-box .patient-box .status-box .not-admitted-box p {
  color: #495057;
}

.patient-card-box .patient-box .status-box .condition-icons-box {
  display: flex;
  gap: 12px;
}

.patient-card-box .patient-box .status-box .condition-icons-box svg {
  width: 100%;
  height: 34px;
  display: block;
}

.patient-card-box .patient-box .status-box .condition-icons-box .oxygen-icon-box svg {
  fill: #000de9;
}

.patient-card-box .patient-box .status-box .condition-icons-box .critical-icon-box svg {
  fill: #dd4c39;
}

.patient-card-box .patient-box .status-box .condition-icons-box .ventilator-icon-box svg {
  fill: #ca2374;
}

.more-dropdown {
  border-radius: 8px;
  border: 1px solid #e3e3e3;
}

.more-dropdown a:hover {
  color: var(--gray-100) !important;
}

.more-dropdown .critical-icon-box.dropdown-item {
  color: var(--gray-singleorange);
}

.more-dropdown .oxygen-icon-box.dropdown-item {
  color: var(--gray-singleblue);
}

.more-dropdown .ventilator-icon-box.dropdown-item {
  color: var(--gray-singlepink);
}

//user-card-css
.user-card-box {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.user-card-box .user-box {
  padding: 16px;
  background: #fff;
  border: 1px solid #e3e3e3;
  border-radius: 16px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.04);
  transition: 0.3s all ease-in-out;
}

// .user-card-box .user-box:hover {
//   border-color: #000de9;
// }
.user-card-box .user-box .user-title-box {
  display: flex;
  align-items: center;
  padding-bottom: 12px;
  justify-content: space-between;
}

.user-card-box .user-box .user-title-box .icon-box {
  display: flex;
  align-items: center;
}

.user-card-box .user-box .user-title-box .icon-box h3 {
  margin: 0;
  padding: 8px 16px;
  background: #e0e2fc;
  border-radius: 8px;
  display: flex;
  font-size: 30px;
  color: #000de9;
  text-transform: uppercase;
}

.user-card-box .user-box .user-title-box h4 {
  padding-left: 12px;
  color: #000de9;
  font-size: 18px;
  margin: 0;
}

.user-card-box .user-box span {
  font-size: 12px;
  padding-bottom: 6px;
  color: #74788d;
  display: block;
}

.user-card-box .user-box p {
  margin: 0;
  font-size: 15px !important;
  color: #495057;
}

.user-card-box .user-box .contact-box .common-box:first-of-type {
  padding-bottom: 12px;
}

.user-card-box .user-box .hospital-box {
  margin-top: 16px;
  border-top: 1px solid #e3e3e3;
  padding-top: 16px;
  display: flex;
  gap: 12px;
  align-items: center;
}

.user-card-box .user-box .hospital-box svg {
  width: auto;
  height: 34px;
  display: block;
}

.user-card-box .user-box .hospital-box .hospital-name svg path {
  fill: #389a09 !important;
}

.user-card-box .user-box .hospital-box h5 {
  font-size: 15px !important;
  color: #389a09;
  font-weight: 400;
  height: auto;
  margin: 0;
}

.user-card-box .user-box .user-title-box .user-setting-box {
  display: flex;
  gap: 8px;
}

.user-card-box .user-box .user-title-box .user-setting-box .edit-box {
  border: 1px solid transparent;
  padding: 6px;
  background: #e0e2f3;
  border-radius: 8px;
  display: flex;
  height: max-content;
}

.user-card-box .user-box .user-title-box .user-setting-box .edit-box svg {
  width: auto;
  height: 20px;
  display: block;
  fill: #495057;
}

.user-card-box .user-box .user-title-box .user-setting-box .delete-box {
  border: 1px solid transparent;
  padding: 6px;
  background: #f7deea;
  border-radius: 8px;
  display: flex;
  height: max-content;
}

.user-card-box .user-box .user-title-box .user-setting-box .delete-box svg {
  width: auto;
  height: 20px;
  display: block;
  fill: #ca2374;
}

.user-card-box .user-box .user-title-box .user-setting-box .edit-box:hover {
  background: transparent;
  border: 1px solid #495057;
}

.user-card-box .user-box .user-title-box .user-setting-box .delete-box:hover {
  background: transparent;
  border: 1px solid #ca2374;
}

.user-roles-box .user-title-box {
  padding-bottom: 0px !important;
}

.patient-list-view-section .table-bordered.table-list,
.table-list td,
.table-list thead {
  border: none;
}

.patient-list-view-section .table-list tbody tr:first-child td {
  border-top: none;
}

.patient-list-view-section .table-list tr:first-child th:first-child {
  border-bottom-left-radius: 0 !important;
}

.patient-list-view-section .table-list tr:first-child th:last-child {
  border-bottom-right-radius: 0 !important;
}

.patient-list-view-section .table-list thead th {
  padding: 16px;
}

.patient-list-view-section .scroll-bar-patient.main-table-con .scroll-bar-patients .card-body {
  padding: 0;
}

.patient-list-view-section .table-list tbody tr td {
  padding: 10px 16px;
}

.patient-list-view-section .icon-box svg {
  width: 34px;
  height: 34px;
  fill: #000de9;
}

.patient-list-view-section .icon-box {
  padding: 8px;
  background: #e0e2fc;
  border-radius: 8px;
  display: flex;
  width: max-content;
}

.patient-list-view-section .patient-card-title {
  display: flex;
  align-items: center;
}

.patient-list-view-section .card-body {
  padding: 0;
}

.patient-list-view-section table tbody tr {
  border-color: #e3e3e3 !important;
  position: relative;
}

.patient-list-view-section .discharge-box {
  width: max-content;
  display: flex;
  gap: 8px;
  background: #ddead6;
  padding: 6px 14px;
  align-items: center;
  border-radius: 8px;
}

.patient-list-view-section .discharge-box svg {
  width: 24px;
  height: 24px;
  display: flex;
  fill: #389a09;
}

.patient-list-view-section .discharge-box p {
  color: #389a09;
}

.patient-list-view-section .admitted-box {
  width: max-content;
  display: flex;
  gap: 8px;
  background: #e0e2fc;
  padding: 6px 14px;
  align-items: center;
  border-radius: 8px;
}

.patient-list-view-section .admitted-box svg {
  width: 24px;
  height: 24px;
  display: flex;
  fill: #000de9;
}

.patient-list-view-section .admitted-box p {
  color: #000de9;
}

.patient-list-view-section .not-admitted-box {
  width: max-content;
  display: flex;
  gap: 8px;
  background: #e0e2e3;
  padding: 6px 14px;
  align-items: center;
  border-radius: 8px;
}

.patient-list-view-section .not-admitted-box svg {
  width: 24px;
  height: 24px;
  display: flex;
  fill: #495057;
}

.patient-list-view-section .not-admitted-box p {
  color: #495057;
}

.patient-list-view-section .condition-icons-box {
  display: flex;
  gap: 12px;
}

.patient-list-view-section .condition-icons-box svg {
  width: 100%;
  height: 34px;
  display: block;
}

.patient-list-view-section .condition-icons-box .oxygen-icon-box svg {
  fill: #000de9;
}

.patient-list-view-section .condition-icons-box .critical-icon-box svg {
  fill: #dd4c39;
}

.patient-list-view-section .condition-icons-box .ventilator-icon-box svg {
  fill: #ca2374;
}

.patient-list-view-section table tbody tr:hover {
  background-color: #f2f3f6;
}

.secondary-tab.nav.nav-tabs {
  width: auto;
}

.secondary-tab .nav-item .nav-link {
  display: flex;
  gap: 8px;
  border-bottom: 2px solid transparent !important;
  padding: 8px 14px !important;
  align-items: center;
}

.secondary-tab .nav-item .nav-link span,
.secondary-tab .nav-item .nav-link i {
  color: #495057 !important;
}

.secondary-tab .nav-item,
.secondary-tab .nav-item a {
  border: none !important;
}

.secondary-tab .nav-item .active {
  border: none !important;
  border-bottom: 2px solid var(--gray-singleblue) !important;
  color: var(--gray-singleblue) !important;
  background: transparent !important;
}

.secondary-tab .nav-item .active span,
.secondary-tab .nav-item .active i {
  color: var(--gray-singleblue) !important;
}

.secondary-tab.single-patient-tab.nav.nav-tabs {
  width: auto;
}

// .patient-open-tab .nav-item .active { background: #E0E2FC !important; border: 1px solid var(--gray-singleblue) !important; }
// .patient-open-tab .nav-item .nav-link.active span { color: var(--gray-singleblue) !important; }
// .patient-open-tab .nav-tabs-custom .nav-item .nav-link.active i { color: var(--gray-singleblue) !important; }
// .patient-open-tab .nav-item .nav-link { padding: 4px 8px !important; border: 1px solid #e3e3e3; }
// .patient-open-tab .nav-tabs-custom { border: none !important; gap: 12px; }
// .patient-open-tab .nav-item .nav-link span { width: auto; }
// .patient-open-tab .nav-item .nav-link { display: flex; align-items: center; gap: 4px; border-radius: 8px; background: var(--gray-100); }
.nav-tabs-custom {
  background: var(--gray-100);
}

.patient-open-tab {
  display: flex;
  width: 100%;
}

.patient-open-tab .nav-tabs-custom {
  width: 100%;
  border: none;
  // border-bottom: 1px solid #e3e3e3;
  background: transparent;
  padding: 0;
}

.patient-open-tab .nav-tabs-custom .nav-item .active {
  padding: 8px 14px !important;
  border: none !important;
  border-bottom: 2px solid var(--gray-singleblue) !important;
  color: var(--gray-singleblue) !important;
  background: transparent !important;
  border-radius: 0;
}

.patient-open-tab .nav-tabs-custom .nav-item .nav-link {
  border-bottom: 2px solid transparent;
  padding: 8px 14px !important;
  display: flex;
  align-items: center;
  gap: 4px;
}

.patient-open-tab .nav-tabs-custom .nav-item .active span,
.patient-open-tab .nav-tabs-custom .nav-item .active i {
  color: var(--gray-singleblue) !important;
}

.patient-open-tab .nav-tabs-custom .nav-item .nav-link i {
  color: inherit !important;
}

.vertical-collpsed .loader-box,
.loader-box {
  top: 0;
  left: 0;
  z-index: 99999;
  height: 100%;
}

.user-list-view-section .table-bordered.table-list,
.table-list td,
.table-list thead {
  border: none;
}

.user-list-view-section .table-list tbody tr:first-child td {
  border-top: none;
}

.user-list-view-section table tbody tr:last-of-type {
  border-bottom: none;
  border-bottom-color: currentcolor;
}

.user-list-view-section .table-list tr:first-child th:first-child {
  border-bottom-left-radius: 0 !important;
}

.user-list-view-section .table-list tr:first-child th:last-child {
  border-bottom-right-radius: 0 !important;
}

.user-list-view-section .table-list thead th {
  padding: 16px;
}

.user-list-view-section .card-body {
  padding: 0;
}

.user-list-view-section .table-list tbody tr td {
  padding: 10px 16px;
}

.user-list-view-section .user-setting-box {
  display: flex;
  gap: 8px;
}

.edit-box {
  cursor: pointer;
}

.delete-box {
  cursor: pointer;
}

.user-list-view-section .user-setting-box .edit-box {
  border: 1px solid transparent;
  padding: 6px;
  background: #e0e2f3;
  border-radius: 8px;
  display: flex;
  height: max-content;
}

.user-list-view-section .user-setting-box .edit-box svg {
  width: auto;
  height: 20px;
  display: block;
  fill: #495057;
}

.user-list-view-section .user-setting-box .delete-box {
  border: 1px solid transparent;
  padding: 6px;
  background: #f7deea;
  border-radius: 8px;
  display: flex;
  height: max-content;
}

.user-list-view-section .user-setting-box .delete-box svg {
  width: auto;
  height: 20px;
  display: block;
  fill: #ca2374;
}

.user-list-view-section .user-setting-box .edit-box:hover {
  background: transparent;
  border: 1px solid #495057;
}

.user-list-view-section .user-setting-box .delete-box:hover {
  background: transparent;
  border: 1px solid #ca2374;
}

.user-list-view-section .icon-box {
  display: flex;
  align-items: center;
}

.user-list-view-section .icon-box h3 {
  width: 52px;
  height: 52px;
  margin: 0;
  padding: 8px 16px;
  background: #e0e2fc;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  font-size: 30px;
  color: #000de9;
  text-transform: uppercase;
}

.user-list-view-section .icon-box h4 {
  padding-left: 12px;
  margin: 0;
  font-size: inherit;
}

.hospital-detail-con {
  height: calc(100vh - 170px) !important;
}

.patient-list-view-section .user-setting-box {
  display: flex;
  gap: 8px;
}

.patient-list-view-section .user-setting-box .edit-box {
  border: 1px solid transparent;
  padding: 6px;
  background: #e0e2f3;
  border-radius: 8px;
  display: flex;
  height: max-content;
}

.patient-list-view-section .user-setting-box .edit-box svg {
  width: auto;
  height: 20px;
  display: block;
  fill: #495057;
}

.patient-list-view-section .user-setting-box .delete-box {
  border: 1px solid transparent;
  padding: 6px;
  background: #f7deea;
  border-radius: 8px;
  display: flex;
  height: max-content;
}

.patient-list-view-section .user-setting-box .delete-box svg {
  width: auto;
  height: 20px;
  display: block;
  fill: #ca2374;
}

.patient-list-view-section .user-setting-box .edit-box:hover {
  background: transparent;
  border: 1px solid #495057;
}

.patient-list-view-section .user-setting-box .delete-box:hover {
  background: transparent;
  border: 1px solid #ca2374;
}

.hospital-card-box .user-setting-box {
  display: flex;
  gap: 8px;
}

.hospital-card-box .user-setting-box .edit-box {
  border: 1px solid transparent;
  padding: 6px;
  background: #e0e2f3;
  border-radius: 8px;
  display: flex;
  height: max-content;
}

.hospital-card-box .user-setting-box .edit-box svg {
  width: auto;
  height: 20px;
  display: block;
  fill: #495057;
}

.light-theme .hospital-detail .hospital-card-box .user-setting-box .edit-box:hover svg path {
  fill: #ffffff !important;
}

.hospital-card-box .brand-detail .user-setting-box .edit-box:hover {
  background: transparent;
  border: 1px solid #ffffff;
}

.brand-detail .hospital-card-box .user-setting-box .edit-box:hover {
  background: transparent;
  border: 1px solid #ffffff;
}

.light-theme .brand-detail .hospital-card-box .user-setting-box .edit-box:hover svg path {
  fill: #ffffff !important;
}


.hospital-card-box .user-setting-box .delete-box {
  border: 1px solid transparent;
  padding: 6px;
  background: #f7deea;
  border-radius: 8px;
  display: flex;
  height: max-content;
}

.hospital-card-box .user-setting-box .delete-box svg {
  width: auto;
  height: 20px;
  display: block;
  fill: #ca2374;
}

.hospital-card-box .user-setting-box .edit-box:hover {
  background: transparent;
  border: 1px solid #495057;
}

.hospital-card-box .user-setting-box .delete-box:hover {
  background: transparent;
  border: 1px solid #ca2374;
}

.hospital-card-box.patient-card-box .patient-box {
  position: relative;
}

.hospital-card-box.patient-card-box .user-setting-box {
  position: absolute;
  right: 16px;
  top: 16px;
}

.hospital-card-box.patient-card-box .patient-box .status-box .discharge-box svg {
  width: auto;
  height: 20px;
}

.hospital-card-box.patient-card-box .patient-box .status-box .discharge-box svg path {
  fill: var(--gray-green);
}

.navbar-brand-box {
  text-align: left;
}

.dark-theme .secondary-tab .nav-item .active {
  border-bottom: 2px solid var(--gray-100) !important;
  color: var(--gray-100) !important;
}

.css-1oj2dtr-MuiFormControl-root-MuiTextField-root .MuiOutlinedInput-input,
.MuiOutlinedInput-notchedOutline {
  border-radius: 8px !important;
}

.dark-theme .MuiFormControl-root svg {
  color: #a6b0cf;
}

.dark-theme .patient-card-box .patient-box {
  background: #0f172a;
  border-color: #384458;
}

.dark-theme .patient-card-box .patient-box p {
  color: var(--gray-100);
}

.dark-theme .patient-card-box .patient-box span {
  color: #a6b0cf;
}

.dark-theme .patient-card-box .patient-box .patient-title-box h4,
.dark-theme .patient-card-box .patient-box .patient-title-box .h4 {
  color: var(--gray-100);
}

.dark-theme .patient-card-box .patient-box .patient-title-box .icon-box {
  background: #2d3a4c;
}

.dark-theme .patient-card-box .patient-box .status-box {
  border-color: #384458;
}

.dark-theme .patient-card-box .patient-box .patient-title-box svg {
  fill: var(--gray-100);
}

.dark-theme .patient-card-box .patient-box .status-box .not-admitted-box {
  background: #2d3a4c;
}

.dark-theme .patient-card-box .patient-box .status-box .not-admitted-box svg {
  fill: var(--gray-100);
}

.dark-theme .patient-card-box .patient-box .status-box .not-admitted-box p {
  color: var(--gray-100);
}

.dark-theme .patient-card-box .patient-box .status-box .admitted-box {
  background: #0177fb;
}

.dark-theme .patient-card-box .patient-box .status-box .admitted-box svg {
  fill: var(--gray-100);
}

.dark-theme .patient-card-box .patient-box .status-box .admitted-box p {
  color: var(--gray-100);
}

.dark-theme .patient-card-box .patient-box .status-box .discharge-box {
  background: #26a82e;
}

.dark-theme .patient-card-box .patient-box .status-box .discharge-box svg {
  fill: var(--gray-100);
}

.dark-theme .patient-card-box .patient-box .status-box .discharge-box p {
  color: var(--gray-100);
}

.dark-theme .form-control:focus {
  border-color: var(--gray-100) !important;
}

.dark-theme .patient-card-box .patient-box:hover {
  border-color: var(--gray-100);
}

.dark-theme .patient-card-box .patient-box .status-box .condition-icons-box .oxygen-icon-box svg {
  fill: #0177fb;
}

.dark-theme .patient-card-box .patient-box .status-box .condition-icons-box .critical-icon-box svg {
  fill: #ff5f2d;
}

.dark-theme .patient-card-box .patient-box .status-box .condition-icons-box .ventilator-icon-box svg {
  fill: #f43f5e;
}

.dark-theme .btn-info {
  background-color: #0177fb;
  border-color: #0177fb;
}

.dark-theme .btn-info:hover {
  background: transparent;
  color: #0177fb;
}

.dark-theme .secondary-tab .nav-item .active span,
.dark-theme .secondary-tab .nav-item .active i {
  color: var(--gray-100) !important;
}

.hospital-card-box.patient-list-view-section .discharge-box svg {
  width: auto;
  height: 20px;
}

.hospital-card-box.patient-list-view-section .discharge-box svg path {
  fill: #389a09;
}

.admission-card .right-box .tab-content .hourly-data-box .MuiDataGrid-virtualScroller {
  max-height: calc(100vh - 370px) !important;
}

.admission-card {
  position: relative !important;
}

// .btn-coman-save {
//   position: absolute !important;
//   top: -87px !important;
//   right: 310px !important;
// }

//.admission-card .right-box { height: calc(100vh - 220px) !important; }
.admission-card .right-box .accordion {
  height: calc(100vh - 320px) !important;
}

.btn-end-coman-save {
  position: absolute !important;
  right: 0 !important;
  top: 0px !important;
}

.dark-theme .user-card-box .user-box {
  background: #0f172a;
  border-color: #384458;
}

.dark-theme .user-card-box .user-box .user-title-box .icon-box h3 {
  background: #2d3a4c;
  color: var(--gray-100);
}

.dark-theme .user-card-box .user-box .user-title-box h4 {
  color: var(--gray-100);
}

.dark-theme .user-card-box .user-box span {
  color: #a6b0cf;
}

.dark-theme .user-card-box .user-box p {
  color: var(--gray-100);
}

.dark-theme .user-card-box .user-box .hospital-box {
  border-color: #384458;
}

.dark-theme .user-card-box .user-box .hospital-box svg path {
  fill: var(--gray-100) !important;
}

.dark-theme .user-card-box .user-box .hospital-box h5 {
  color: var(--gray-100);
}

.dark-theme .user-card-box .user-box .user-title-box .user-setting-box .edit-box:hover {
  border-color: var(--gray-100);
}

.dark-theme .user-card-box .user-box .user-title-box .user-setting-box .edit-box:hover svg path {
  fill: var(--gray-100);
}

// .dark-theme .user-card-box .user-box:hover {
//   border-color: var(--gray-100);
// }

.dark-theme .patient-list-view-section table tbody tr {
  border-color: #384458 !important;
}

.dark-theme .patient-list-view-section .table-bordered.table-list tbody {
  background: #0f172a !important;
}

.dark-theme table thead tr {
  border: none !important;
}

.dark-theme .patient-list-view-section .table-list thead th {
  border-color: transparent;
}

.dark-theme .patient-list-view-section table tbody tr:hover {
  background: #2d3a4c;
}

.dark-theme .patient-list-view-section .admitted-box {
  background: #0177fb;
}

.dark-theme .patient-list-view-section .admitted-box svg {
  fill: var(--gray-100);
}

.dark-theme .patient-list-view-section .admitted-box p {
  color: var(--gray-100);
}

.dark-theme .patient-list-view-section .not-admitted-box {
  background: #2d3a4c;
}

.dark-theme .patient-list-view-section .not-admitted-box svg {
  fill: var(--gray-100);
}

.dark-theme .patient-list-view-section .not-admitted-box p {
  color: var(--gray-100);
}

.dark-theme .patient-list-view-section .discharge-box {
  background: #26a82e;
}

.dark-theme .patient-list-view-section .discharge-box svg {
  fill: var(--gray-100);
}

.dark-theme .patient-list-view-section .discharge-box p {
  color: var(--gray-100);
}

.dark-theme .patient-list-view-section .condition-icons-box .oxygen-icon-box svg {
  fill: #0177fb;
}

.dark-theme .patient-list-view-section .condition-icons-box .critical-icon-box svg {
  fill: #ff5f2d;
}

.dark-theme .patient-list-view-section .condition-icons-box .ventilator-icon-box svg {
  fill: #f43f5e;
}

.dark-theme .patient-list-view-section #page-header-user-dropdown svg path {
  fill: #a6b0cf;
}

.dark-theme .patient-card-box .patient-box #page-header-user-dropdown svg path {
  fill: #a6b0cf;
}

.dark-theme .hospital-card-box.patient-list-view-section .discharge-box svg path {
  fill: var(--gray-100);
}

.common-top-fix-title {
  &:first-child {
    top: 50px;
    position: fixed;
    width: 100%;
    background: #f3f4f7;
    z-index: 99;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: 0 !important;
    left: 70px;
    padding: 12px 90px 12px 190px;
  }
}

.common-top-con {
  margin-top: 55px;
}

.serchbar-panel-hospital {
  justify-content: end;
}

.dark-theme .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--gray-100) !important;
}

.dark-theme .common-top-fix-title:first-child {
  background: #010617;
}

.instruction-right-tab {
  border: none !important;

  .all-complete-btn {
    margin-left: 10px;
  }
}

.vertical-collpsed {
  .common-top-fix-title:first-child {
    padding: 12px 90px 12px 15px;
  }
}

.dark-theme .dropdown-lbl {
  color: var(--gray-100) !important;
}

.dark-theme .common-top-fix-title:first-child {
  background: #020617 !important;
}

.dark-theme .hospital-card-box .user-setting-box .edit-box:hover {
  border-color: var(--gray-100) !important;
}

.dark-theme .hospital-card-box .user-setting-box .edit-box:hover svg path {
  fill: var(--gray-100) !important;
}

.dark-theme .user-list-view-section .user-setting-box .edit-box:hover {
  border-color: var(--gray-100);
}

.dark-theme .user-list-view-section .user-setting-box .edit-box:hover svg path {
  fill: var(--gray-100) !important;
}

.dark-theme .user-list-view-section .table-bordered.table-list tbody {
  background: #0f172a;
}

.report-header-padding {
  margin-left: 17px;
}

// .dark-theme .form-control .error{ background: #fae2e2 !important; border-color: red !important;}
// .dark-theme .form-control::placeholder .error, .dark-theme select { color: #030303 !important; }
// .dark-themeinput .\"form-control::placeholder\".error.form-control::placeholder{ color: #030303 !important; }
.dark-theme input.\"form-control\".error.form-control {
  background: #fae2e2 !important;
  border-color: red !important;
  color: #010617 !important;
}

.dark-theme input.\"form-control\".error.form-control::placeholder {
  color: #8e8e8e !important;
}

.user-role-list-content .user-card-box .contact-box {
  display: flex;
  justify-content: space-between;
  padding-bottom: 6px;
}

.doctor-profile-details {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  // padding-top: 50px;
}

.doctor-profile-details .doctor-img-box {
  border-radius: 16px;
  padding: 6px;
  border: 1px solid #e3e3e3;
  background: #fff;
}

.doctor-profile-details .doctor-img-box img {
  width: 100%;
  border-radius: 8px;
}

.doctor-profile-details .doctor-main-details-box {
  padding: 16px;
  border: 1px solid #e3e3e3;
  background: #fff;
  border-radius: 16px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.doctor-profile-details .doctor-main-details-box .name-box {
  display: flex;
  align-items: center;
  gap: 24px;
}

.doctor-profile-details .doctor-main-details-box .name-box span {
  font-size: 15px;
  color: #000de9;
  background: #e0e2fc;
  padding: 6px 14px;
  border-radius: 8px;
}

.doctor-profile-details .doctor-main-details-box .name-box h4 {
  font-size: 18px;
  color: #000de9;
  margin: 0;
}

.doctor-profile-details .doctor-main-details-box .details-box {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
  width: 100%;
}

.doctor-profile-details .doctor-main-details-box .details-box .common-box {
  padding: 16px;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
}

.doctor-profile-details .doctor-main-details-box .details-box .common-box span {
  font-size: 12px;
  color: #74788d;
  font-weight: normal;
  display: block;
  padding-bottom: 6px;
}

.doctor-profile-details .doctor-main-details-box .details-box .common-box p {
  font-size: 15px;
  color: #495057;
  margin: 0;
}

.doctor-list-view svg {
  width: auto;
  height: 30px;
}

.doctor-list-view .clinical-icon td {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.doctor-filter-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.doctor-filter-row .page-title-box {
  flex: 1;
}

.doctor-filter-row .page-title-box:last-of-type {
  text-align: right;
}

.doctor-date .MuiFormControl-root .MuiOutlinedInput-root {
  background: var(--gray-100) !important;
}

.doctor-date .MuiFormControl-root .MuiOutlinedInput-root {
  background: var(--gray-100) !important;
}

.dark-theme .tab-content .card {
  background: #0f172a;
}

.dark-theme .user-list-view-section .table-list thead th {
  border-color: transparent;
}

.dark-theme .user-list-view-section table tbody tr {
  border-color: #384458 !important;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li:hover a {
  background: #000de9;
}

.light-theme .right-panel {
  background: #f3f4f7;
}

.light-theme .right-panel h4 {
  color: #000de9;
}

.dark-theme .doctor-profile-details .doctor-main-details-box {
  background: #0f172a;
  border-color: #384458;
}

.dark-theme .doctor-profile-details .doctor-main-details-box .details-box .common-box {
  border-color: #384458;
}

.dark-theme .doctor-profile-details .doctor-img-box {
  background: #0f172a;
  border-color: #384458;
}

.right-panel .doctor-note-box {
  background: var(--gray-100);
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.right-panel .doctor-note-box p {
  margin: 0;
}

.patient-profile-inner-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.patient-profile-inner-section .patient-profile-details-box {
  background: #fff;
  border: 1px solid #e3e3e3;
  padding: 16px;
  border-radius: 16px;
  position: relative;
  border-top-right-radius: 0px;
}
.patient-profile-inner-section .patient-profile-details-box.status-box {
  border-top-right-radius: 16px;
}
.patient-profile-inner-section .patient-profile-details-box .patient-title-box .icon-box {
  padding:12px;
  background: #e0e2fc;
  border-radius: 8px;
  display: flex;
}

.patient-profile-inner-section .patient-profile-details-box .patient-title-box h4 {
  color: #000de9;
  font-size: 18px;
  margin: 0;
  text-transform: uppercase;
  display: block;
  padding-bottom: 8px;
}

.patient-profile-inner-section .patient-title-box {
  display: flex;
  align-items: center;
  padding-bottom: 12px;
}

.patient-profile-inner-section .patient-title-box svg {
  width: 65px;
  height: 65px;
  fill: #000de9;
}

.patient-profile-inner-section .patient-title-box p {
  display: block;
}

.patient-profile-inner-section .patient-title-box .name-box {
  padding-left: 16px;
  width: 40%;
}

.patient-profile-inner-section .patient-title-box .name-box p {
  padding-bottom: 8px;
  color: #495057;
  margin: 0;
}

.patient-profile-inner-section .patient-title-box .name-box p:last-of-type {
  padding-bottom: 0;
}

// .patient-profile-inner-section .patient-personal-info-box {
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
// }

.patient-profile-inner-section .patient-personal-info-box .patient-left-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px 16px;
}

.patient-profile-inner-section .patient-personal-info-box .common-box span {
  font-size: 12px;
  color: #74788d;
  font-weight: normal;
  display: block;
  padding-bottom: 4px;
}

.patient-profile-inner-section .patient-personal-info-box .common-box p {
  font-size: 14px;
  color: #495057;
  margin: 0;
}

.patient-profile-inner-section .patient-personal-info-box .patient-right-box h5 {
  font-size: 16px;
  color: #000de9;
}

.patient-profile-inner-section .patient-personal-info-box .right-box-inner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px 16px;
}

.patient-profile-inner-section .patient-personal-info-box .right-box-inner .common-box:nth-child(4) {
  grid-column: 1 / span 3;
  width: 100%;
}

.patient-profile-inner-section .patient-health-data-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 10px;
  gap: 16px;
  padding-bottom: 10px;
}

.patient-profile-inner-section .patient-health-data-box .common-box {
  padding: 8px;
  border: 1px solid #e3e3e3;
  border-radius: 16px;
}
.patient-profile-inner-section  .common-box span {
  font-size: 12px;
  color: #74788d;
  font-weight: normal;
  display: block;
  padding-bottom: 4px;
}
.patient-profile-inner-section .patient-health-data-box .icon-box {
  display: flex;
  align-items: center;
  gap: 12px;

}
.patient-profile-inner-section .patient-health-data-box .icon-box h4{
  font-size: 0.8rem;
}
.patient-profile-inner-section .patient-health-data-box .box-1 .icon-box svg {
  padding: 6px;
  background: #fcdbd1;
  border-radius: 8px;
  width: auto;
  height: 35px;
}

.patient-profile-inner-section .patient-health-data-box .box-1 .icon-box svg path {
  fill: #ff0000;
}

.patient-profile-inner-section .patient-health-data-box .box-1 .icon-box h4 {
  color: #ff0000;
}

.patient-profile-inner-section .patient-health-data-box .common-box h3 {
  color: #495057;
  font-size: 14px;
  margin: 0;
}

.patient-profile-inner-section .patient-health-data-box .common-box h3 span {
  font-size: 16px;
  color: #74788d;
}

.patient-profile-inner-section .patient-health-data-box .box-2 .icon-box svg {
  padding: 6px;
  background: #e0e2fc;
  border-radius: 8px;
  width: auto;
  height: 35px;
}

.patient-profile-inner-section .patient-health-data-box .box-2 .icon-box svg path {
  fill: #000de9;
}

.patient-profile-inner-section .patient-health-data-box .box-2 .icon-box h4 {
  color: #000de9;
  margin: 0;
}

.patient-profile-inner-section .patient-health-data-box .box-3 .icon-box svg {
  padding: 6px;
  background: #ede0ff;
  border-radius: 8px;
  width: auto;
  height: 35px;
}

.patient-profile-inner-section .patient-health-data-box .box-3 .icon-box svg path {
  fill: #6900ff;
}

.patient-profile-inner-section .patient-health-data-box .box-3 .icon-box h4 {
  color: #6900ff;
  margin: 0;
}

.patient-profile-inner-section .patient-health-data-box .box-4 .icon-box svg {
  padding: 6px;
  background: #fbeae7;
  border-radius: 8px;
  width: auto;
  height: 35px;
}

.patient-profile-inner-section .patient-health-data-box .box-4 .icon-box svg path {
  fill: #dd4c39;
}

.patient-profile-inner-section .patient-health-data-box .box-4 .icon-box h4 {
  color: #dd4c39;
  margin: 0;
}

.patient-profile-inner-section .patient-health-data-box .box-5 .icon-box svg {
  padding: 6px;
  background: #f9e5ee;
  border-radius: 8px;
  width: auto;
  height: 35px;
}

.patient-profile-inner-section .patient-health-data-box .box-5 .icon-box svg path {
  fill: #ca2374;
}

.patient-profile-inner-section .patient-health-data-box .box-5 .icon-box h4 {
  color: #ca2374;
  margin: 0;
}

.patient-profile-inner-section .patient-health-data-box .box-6 .icon-box svg {
  padding: 6px;
  background: #ddead6;
  border-radius: 8px;
  width: auto;
  height: 35px;
}

.patient-profile-inner-section .patient-health-data-box .box-6 .icon-box svg path {
  fill: #389a09;
}

.patient-profile-inner-section .patient-health-data-box .box-6 .icon-box h4 {
  color: #389a09;
  margin: 0;
}

.patient-profile-inner-section .patient-data-profile-box {
  background: #fff;
  border: 1px solid #e3e3e3;
  padding: 16px;
  border-radius: 16px;
  position: relative;
}

.no-data-icon-box {
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.patient-profile-inner-section .patient-data-profile-box h4 {
  color: #74788d;
  margin: 0;
  padding-bottom: 12px;
}

.patient-profile-inner-section .patient-profile-box .overview-main .inner-box .inner-con-box {
  border-left: 2px solid #000de9 !important;
  padding-left: 22px !important;
}

.patient-profile-inner-section .patient-profile-box .overview-main h6::after {
  position: absolute;
  left: -24px !important;
  top: 0px !important;
  width: 16px !important;
  height: 15px !important;
  display: block;
  border-radius: 0 !important;
  content: "";
  background-color: transparent !important;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNiAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzEwMTlfNDI5MCkiPgo8cGF0aCBkPSJNMTUuNjY4MyA2LjkxODEyTDAuODExNTA5IDAuMDYxMTY0QzAuNTc0OTQzIC0wLjA0NjI2MTkgMC4yOTI2NjQgMC4wMTc3MzY1IDAuMTI4MDk2IDAuMjIxMTZDMC4wNDc1NjkxIDAuMzE5Njk0IDAuMDAyNDQ3NjEgMC40NDI0MDkgLTMuODk3NjhlLTA1IDAuNTY5NjM3Qy0zLjg5NzY4ZS0wNSAwLjcwMzEyNSAtMC4wMDAyOTc1NzkgMS4wMTQ2NCAtMC4wMDAyOTgyNTcgMC45MjE4NzVWNy40MzY5N0wtMC4wMDAyODI0ODQgMTMuOTM3NUMtMC4wMDAyNTQyNjcgMTQuMTU2MiAtMC4wMzc2MTQxIDE0LjQ1MDUgMC4xMjY5NTMgMTQuNjUyOEMwLjIzNzgwOCAxNC43OTExIDAuNDAzNTE4IDE0Ljg2NTQgMC41NzE1MTUgMTQuODY1NEMwLjY1MjY1NiAxNC44NjU0IDAuNzMzNzk2IDE0Ljg0ODIgMC44MTAzNjUgMTQuODEyOEwxNS42NjcxIDcuOTU1ODFDMTUuODcwNiA3Ljg2MjEyIDE1Ljk5OTcgNy42NTk4NCAxNS45OTk3IDcuNDM2OTdDMTUuOTk5NyA3LjIxNDEyIDE1Ljg3MDYgNy4wMTE4NCAxNS42NjgzIDYuOTE4MTJaIiBmaWxsPSIjMDAwREU5Ii8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMTAxOV80MjkwIj4KPHJlY3Qgd2lkdGg9IjE2IiBoZWlnaHQ9IjE0Ljg3NSIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K) !important;
}

.no-data-icon-box {
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.patient-profile-inner-section .patient-profile-box .overview-main h6 {
  font-size: 14px !important;
  color: #000de9 !important;
}

.patient-profile-inner-section .patient-profile-box .overview-main .inner-box .inner-con-box div p {
  font-size: 14px !important;
}

.patient-profile-inner-section .patient-profile-box .overview-main .inner-box .inner-con-box li {
  margin-left: 12px !important;
  font-size: 14px !important;
}

.patient-profile-inner-section .patient-profile-box .overview-main .inner-box .inner-con-box li b {
  font-weight: 600 !important;
}

.patient-profile-inner-section .patient-profile-box .overview-main {
  height: 445px;
  overflow: auto;
  // scrollbar-width: none;
}

.patient-profile-inner-section .patient-profile-details-box .condition-icons-box {
  display: flex;
  gap: 12px;
}

.patient-profile-inner-section .patient-profile-details-box .condition-icons-box svg {
  width: 100%;
  height: 25px;
  display: block;
}

.patient-profile-inner-section .patient-profile-details-box .condition-icons-box .oxygen-icon-box svg {
  fill: #000de9;
}

.patient-profile-inner-section .patient-profile-details-box .condition-icons-box .critical-icon-box svg {
  fill: #dd4c39;
}

.patient-profile-inner-section .patient-profile-details-box .condition-icons-box .ventilator-icon-box svg {
  fill: #ca2374;
}

.patient-profile-inner-section .patient-profile-details-box .demo .drop-menu-right {
  position: absolute;
  right: -1px;
  top: -35px;
  z-index: 9;
  background: #fff;
  border-top-right-radius: 12px;
  padding: 0px 5px;
  border-top-left-radius: 12px;
  border-top: 1px solid #e3e3e3;
  border-right: 1px solid #e3e3e3;
  border-left: 1px solid #e3e3e3;
}
  
.patient-profile-inner-section .patient-profile-details-box .status-box {
     position: absolute;
  right: 29px;
  top: -35px;
  background: #fff;
  padding: 10px 10px 0px 10px;
  z-index: 10;
  border-top-left-radius: 16px;
  border-top: 1px solid #e3e3e3;
  border-left: 1px solid #e3e3e3;

}

.patient-profile-inner-section .patient-profile-details-box .demo svg {
  width: inherit !important;
  height: inherit !important;
}

.recent-reports-and-notifications-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-top: 16px;
}

.recent-reports-and-notifications-section .notifications-box {
  background: #fff;
  border: 1px solid #e3e3e3;
  padding: 16px;
  border-radius: 16px;
  height: 500px;
  position: relative;
}

.recent-reports-and-notifications-section .recent-reports-box {
  background: #fff;
  border: 1px solid #e3e3e3;
  padding: 16px;
  border-radius: 16px;
  height: 500px;
  position: relative;
}

.recent-reports-and-notifications-section .title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 12px;
}

.recent-reports-and-notifications-section .title-box h4 {
  color: #74788d;
  margin: 0;
}

.recent-reports-and-notifications-section .no-data-icon-box {
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.recent-reports-and-notifications-section .no-data-icon-box h3 {
  color: #74788d;
  margin: 0;
}

.recent-reports-and-notifications-section .notifications-box ul {
  padding-left: 0;
  margin: 0;
  height: 416px;
  overflow: scroll;
  scrollbar-width: none;
}

.recent-reports-and-notifications-section .notifications-box ul li {
  list-style: none;
  margin-bottom: 10px;
}

.recent-reports-and-notifications-section .notifications-box ul li span {
  border-radius: 50%;
  display: inline-block;
  font-size: 16px;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  padding-top: 3px;
  text-align: center;
}

.recent-reports-and-notifications-section .notifications-box ul li .one-color {
  background-color: #e0e2fc;
  color: #000de9;
}

.recent-reports-and-notifications-section .notifications-box ul li .two-color {
  background-color: #ede0ff;
  color: #6900ff;
}

.recent-reports-and-notifications-section .notifications-box ul li .three-color {
  background-color: #ddead6;
  color: #389a09;
}

.doctor-list-section {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;
  padding-top: 50px;
}

.doctor-list-section .common-box {
  background: #fff;
  border-radius: 16px;
  border: 1px solid #e3e3e3;
}

.doctor-list-section .common-box img {
  border-radius: 14px;
  width: 100%;
  margin-bottom: 8px;
  padding: 8px;
  display: block;
}

.doctor-list-section .details-box {
  padding: 16px;
  padding-top: 0;
}

.doctor-list-section .common-box h4 {
  font-size: 18px;
  color: #000de9;
  margin: 0;
  padding-bottom: 6px;
}

.doctor-list-section .common-box span {
  font-size: 12px;
  color: #74788d;
  padding-bottom: 6px;
  display: block;
}

.doctor-list-section .common-box p {
  font-size: 15px;
  color: #495057;
}

.doctor-list-section .common-box .department-box {
  margin-top: 6px;
  border-top: 1px solid #e3e3e3;
  padding-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.doctor-list-section .common-box .department-box span {
  font-size: 12px;
  color: #000de9;
  background: #e0e2fc;
  padding: 6px 14px;
  border-radius: 8px;
}

.doctor-list-section .common-box .department-box p {
  display: list-item;
  font-size: 12px;
  margin: 0;
  color: #389a09;
}

.dark-theme .secondary-tab .nav-item .nav-link span,
.dark-theme .secondary-tab .nav-item .nav-link i {
  color: #a6b0cf !important;
}

.dark-theme .secondary-tab .nav-item .active span,
.dark-theme .secondary-tab .nav-item .active i {
  color: var(--gray-100) !important;
}

.dark-theme .patient-profile-inner-section .patient-profile-details-box {
  background: #0f172a;
  border-color: #384458;
}

.dark-theme .patient-profile-inner-section .patient-personal-info-box .common-box span {
  color: #a6b0cf;
}

.dark-theme .patient-profile-inner-section .patient-personal-info-box .common-box p {
  color: var(--gray-100);
}

.dark-theme .patient-profile-inner-section .patient-personal-info-box .patient-right-box h5 { color: #0177fb;
}

.dark-theme .patient-profile-inner-section .patient-profile-details-box .demo svg path {
  fill: #a6b0cf;
}

.dark-theme .patient-profile-inner-section .patient-health-data-box .common-box {
  border-color: #384458;
}

.dark-theme .patient-profile-inner-section .patient-health-data-box .common-box h3 {
  color: var(--gray-100);
}

.dark-theme .patient-profile-inner-section .patient-health-data-box .common-box h3 span {
  color: #a6b0cf;
}

.dark-theme .patient-profile-inner-section .patient-health-data-box .box-1 .icon-box h4 {
  color: #ee2020;
}

.dark-theme .patient-profile-inner-section .patient-title-box .name-box p {
  color: var(--gray-100);
}

.dark-theme .patient-profile-inner-section .patient-profile-details-box .condition-icons-box .oxygen-icon-box svg {
  fill: #0177fb;
}

.dark-theme .patient-profile-inner-section .patient-profile-details-box .condition-icons-box .critical-icon-box svg,
.dark-theme .patient-profile-inner-section .patient-health-data-box .box-4 .icon-box svg path {
  fill: #ff5f2d;
}

.dark-theme .patient-profile-inner-section .patient-profile-details-box .condition-icons-box .ventilator-icon-box svg,
.dark-theme .patient-profile-inner-section .patient-health-data-box .box-5 .icon-box svg path {
  fill: #f43f5e;
}

.dark-theme .patient-profile-inner-section .patient-profile-details-box .patient-title-box h4,
.dark-theme .patient-profile-inner-section .patient-health-data-box .box-2 .icon-box h4 {
  color: #0177fb;
}

.dark-theme .patient-profile-inner-section .patient-title-box svg,
.dark-theme .patient-profile-inner-section .patient-health-data-box .box-2 .icon-box svg path {
  fill: #0177fb;
}

.dark-theme .patient-profile-inner-section .patient-health-data-box .box-4 .icon-box h4 {
  color: #ff5f2d;
}

.dark-theme .patient-profile-inner-section .patient-health-data-box .box-5 .icon-box h4 {
  color: #f43f5e;
}

.dark-theme .patient-profile-inner-section .patient-health-data-box .box-3 .icon-box h4 {
  color: #954bff;
}

.dark-theme .patient-profile-inner-section .patient-health-data-box .box-3 .icon-box svg path {
  fill: #954bff;
}

.dark-theme .patient-profile-inner-section .patient-health-data-box .box-6 .icon-box h4 {
  color: #26a82e;
}

.dark-theme .patient-profile-inner-section .patient-health-data-box .box-6 .icon-box svg path {
  fill: #26a82e;
}

.dark-theme .patient-profile-inner-section .patient-data-profile-box {
  background: #0f172a;
  border-color: #384458;
}

.dark-theme .patient-profile-inner-section .patient-data-profile-box h4,
.dark-theme .patient-profile-inner-section .patient-profile-box .overview-main h6 {
  color: #0177fb !important;
}

.dark-theme .patient-profile-inner-section .patient-profile-box .overview-main h6::after {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNiAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzEwMTlfNDI5MCkiPgo8cGF0aCBkPSJNMTUuNjY4MyA2LjkxODEyTDAuODExNTA5IDAuMDYxMTY0QzAuNTc0OTQzIC0wLjA0NjI2MTkgMC4yOTI2NjQgMC4wMTc3MzY1IDAuMTI4MDk2IDAuMjIxMTZDMC4wNDc1NjkxIDAuMzE5Njk0IDAuMDAyNDQ3NjEgMC40NDI0MDkgLTMuODk3NjhlLTA1IDAuNTY5NjM3Qy0zLjg5NzY4ZS0wNSAwLjcwMzEyNSAtMC4wMDAyOTc1NzkgMS4wMTQ2NCAtMC4wMDAyOTgyNTcgMC45MjE4NzVWNy40MzY5N0wtMC4wMDAyODI0ODQgMTMuOTM3NUMtMC4wMDAyNTQyNjcgMTQuMTU2MiAtMC4wMzc2MTQxIDE0LjQ1MDUgMC4xMjY5NTMgMTQuNjUyOEMwLjIzNzgwOCAxNC43OTExIDAuNDAzNTE4IDE0Ljg2NTQgMC41NzE1MTUgMTQuODY1NEMwLjY1MjY1NiAxNC44NjU0IDAuNzMzNzk2IDE0Ljg0ODIgMC44MTAzNjUgMTQuODEyOEwxNS42NjcxIDcuOTU1ODFDMTUuODcwNiA3Ljg2MjEyIDE1Ljk5OTcgNy42NTk4NCAxNS45OTk3IDcuNDM2OTdDMTUuOTk5NyA3LjIxNDEyIDE1Ljg3MDYgNy4wMTE4NCAxNS42NjgzIDYuOTE4MTJaIiBmaWxsPSIjMDE3N0ZCIi8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMTAxOV80MjkwIj4KPHJlY3Qgd2lkdGg9IjE2IiBoZWlnaHQ9IjE0Ljg3NSIgZmlsbD0id2hpdGUiLz4KPC9jbGlwUGF0aD4KPC9kZWZzPgo8L3N2Zz4K) !important;
}

.dark-theme .patient-profile-inner-section .patient-profile-box .overview-main .inner-box .inner-con-box {
  border-color: #0177fb !important;
}

.dark-theme .recent-reports-and-notifications-section .notifications-box,
.dark-theme .recent-reports-and-notifications-section .recent-reports-box {
  background: #0f172a;
  border-color: #384458;
}

.dark-theme .recent-reports-and-notifications-section .title-box h4 {
  color: #0177fb;
}

.dark-theme .patient-profile-inner-section .patient-profile-details-box .demo .dropdown-menu {
  background: #020617 !important;
  border-color: #384458 !important;
}

.dark-theme .patient-card-box .patient-box .dropdown-menu {
  background: #020617 !important;
  border-color: #384458 !important;
}

.dark-theme .navbar-brand-box .light-logo-image,
.light-theme .navbar-brand-box .dark-logo-image {
  margin-top: 10px;
}

.light-theme .light-logo-image {
  display: none;
}

.dark-theme .dark-logo-image {
  display: none;
}

.dark-theme .light-logo-image {
  display: block;
}

.light-theme .sidebar-bottom .dark-logo-image,
.dark-theme .sidebar-bottom .light-logo-image {
  margin: 10px auto 0;
}

.dark-theme .doctor-list-section .common-box {
  background: #0f172a;
  border-color: #384458;
}

.dark-theme .doctor-list-section .common-box h4 {
  color: var(--gray-100);
}

.dark-theme .doctor-list-section .common-box span {
  color: #a6b0cf;
}

.dark-theme .doctor-list-section .common-box .department-box span {
  color: #0134cf;
}

.dark-theme .doctor-list-section .common-box p {
  color: var(--gray-100);
}

.dark-theme .doctor-profile-details .doctor-main-details-box .details-box .common-box span {
  color: #a6b0cf;
}

.dark-theme .doctor-profile-details .doctor-main-details-box .details-box .common-box p {
  color: var(--gray-100);
}

.dark-theme .doctor-date .MuiFormControl-root .MuiOutlinedInput-root {
  background: transparent !important;
}

.dark-theme .doctor-profile-details .doctor-main-details-box .name-box h4 {
  color: var(--gray-100);
}

.doctor-tabs-code .tabs {
  display: flex;
}

.vertical-collpsed {
  .vertical-menu {
    #sidebar-menu {
      ul {
        li {
          &:hover {
            a {
              background: var(--gray-singleblue);

              i {
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

.add-row-btn {
  display: flex;
  gap: 16px;
  align-items: center;
}

.add-row-btn .filter-btn-group {
  margin: 0 !important;
}

.add-row-btn .filter-btn-group .form-control {
  width: 200px;
}

.add-row-btn .tab-title {
  margin: 0 !important;
}

.add-row-btn .btn-primary {
  margin: 0 !important;
}

.dashboard-dropdown .col-3 {
  width: auto !important;
}

.dark-theme .doctor-list-view .clinical-icon svg {
  fill: white !important;
}

.dark-theme .doctor-list-view .clinical-icon-viewdetails svg {
  fill: white !important;
}

.four-drpdown .col-3 {
  width: auto !important;
}


.patient-search-box .search-box-wrapper {
  position: relative;
}

.patient-search-box .search-box-wrapper input {
  padding-left: 30px;
  /* Adjust this value based on the size of your SVG icon */
}

.patient-search-box .search-box-wrapper svg {
  width: 20px;
  position: absolute;
  left: 28px;
  /* Adjust this value to position the icon */
  top: 50%;
  transform: translateY(-50%);
  fill: var(--gray-100);
  pointer-events: none;
  /* This ensures the icon doesn't block input clicks */
}

.light-theme .patient-search-box .search-box-wrapper svg {
  fill: #495053;
}

.patient-search-box .search-box-wrapper .search-box-icon svg {
  left: 9px;
  top: 36%;
}

.patient-search-box .search-box-wrapper .search-hospital-icon svg {
  left: 9px !important;
}


.patient-basicinfo .patient-title-box .icon-box svg {
  background: #e0e2fc;
  border-radius: 8px;
  height: 70px;
  padding: 8px;
  width: auto;
  fill: #000de9;
}

.hospital-icon-box .patient-title-box .icon-box svg {
  background: #e0e2fc;
  border-radius: 8px;
  height: 60px;
  padding: 8px;
  width: auto;
  fill: #000de9;
}

.hospital-card-box .title h4 {
  margin-bottom: 5px;
  padding-left: 10px;
  font-size: 25px;
  text-transform: uppercase;
}

.address-basicinfo {
  padding-left: 10px;
  font-size: 13px;
}

.no-data-found {
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: fixed;
  top: 50%;
}

.dropdown-error {
  background: #fae2e2 !important;
  border-color: red !important;
  color: #010617 !important;
}

.admission-doctor {
  padding-left: 20px;
}
.admission-doctor .common-box p{
  font-size: 14px;
  color: #495057;
  margin-bottom: 5px !important;
}
.dashboard-patient-view svg {
  height: 22px;
  width: auto;
}

.dark-theme .dashboard-patient-view svg {
  fill: #A6B0CF;
}

.dashboard-model {
  height: 500px;
  overflow-y: auto;
  // scrollbar-width: none;
}

.hourlyreport-table-size .MuiFormControl-root input {
  padding: 8px 16px !important;
  height: auto !important;
}


.hourlyreport-table-size .fixed-table-top input {
  padding: 4px 12px !important;
  height: auto !important;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #dddddd;
  width: 100%;
}

.hourlyreport-table-size .time-col {
  width: 95px;
}

.dark-theme .hourlyreport-table-size .fixed-table-top input {
  background-color: #2d3a4c;
  color: white;
}

.complete-box {
  width: max-content;
  display: flex;
  gap: 8px;
  background: #ddead6;
  padding: 6px 14px;
  align-items: center;
  border-radius: 8px;
}

.complete-box p {
  color: #389a09;
}

.pending-box {
  width: max-content;
  display: flex;
  gap: 8px;
  background: #ead8d6;
  padding: 6px 14px;
  align-items: center;
  border-radius: 8px;
}

.pending-box p {
  color: #9a0909;
}

.page-name .breadcrumb-item {
  font-size: 22px !important;
}

.main-content-inside-reset {
  .main-content {
    margin: 0;

    .page-content {
      padding: 0;

      .container-fluid {
        padding: 0;
      }
    }
  }
}

.report-save-all-btn {
  position: absolute;
  top: -81px;
  right: 310px;
  z-index: 1;
}

.hourly-table-scroll {
  max-height: calc(100vh - 320px);
  overflow-y: auto;
}

.no-margin {
  margin: 0 !important;
}

.delete-box {
  color: #ca2374;
}

.light-theme .form-control {
  background-color: #ffffff !important;
  border-color: #dddddd !important;
  color:#828282 !important
}

.light-theme .form-control::placeholder,
.light-theme select {
  color: #111111 !important;
}

.delete-box-disable {
  background: #b3acaf !important;
  color: #ffffff;
}

.delete-box-disable:hover {
  color: #ffffff;
}

.instruction-sort-control {
  width: 200px !important;
  margin: 0px !important;
}

.tab-content .doctor-list-section .common-box:hover {
  border-color: var(--gray-100);
  cursor: pointer;
}

.light-theme .tab-content .doctor-list-section .common-box:hover {
  border-color: #000de9;
}

.dark-theme .MuiPaper-rounded {background-color: #222736;
    color: #eff2f7;}
    .dark-theme .MuiTypography-caption, .dark-theme .MuiButtonBase-root{color: #eff2f7;}
.dark-theme .patient-open-tab .nav-tabs-custom .nav-item .active {
  border-bottom: 2px solid var(--gray-100) !important;
  color: var(--gray-100) !important;
}
.dark-theme .patient-profile-inner-section .patient-profile-details-box .status-box{
  background:#0f172a; border-color: #384458;
}
.dark-theme .patient-profile-inner-section .patient-profile-details-box .demo .drop-menu-right{
  background-color: #0f172a; border-color: #384458;
}
.dark-theme .patient-open-tab .nav-tabs-custom .nav-item span {
  color: var(--gray-100) !important;
}

.dark-theme  .patient-profile-inner-section .patient-profile-details-box .patient-title-box .icon-box {
  background: #2d3a4c;}

  .dark-theme .patient-profile-inner-section  .common-box p {
    color: var(--gray-100);
}
.instraction-dropdown .MuiInputBase-formControl {
  width: 77%;
}

.instraction-dropdown .MuiInputLabel-formControl {
  font-size: 14px;
}

.doctorlist-filters {
  display: flex;
  justify-content: flex-start !important;
  gap: 20px;
}

.doctorlist-filters .filter-btn-group .form-control {
  margin-left: 0;
}

.doctorlist-filters .toggle-btn-group {
  width: 25% !important;
}

.dp-aco-field .detail {
  white-space: pre-wrap;
  overflow: visible;
  width: 100%;
}

.dp-aco-field p {
  // display: flex;
  gap: 10px;
}

.doctor-list-view .clinical-icon {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
}

.doctor-list-view .clinical-icon p {
  max-width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* OtpInputStyles.css */
.otp-container {
  display: flex;
  justify-content: center;
  gap: 5px; /* Ensure there is space between inputs */
}
 
.otp-input {
  background-color: transparent;
  width: 40px !important; /* Adjust width if needed */
  height: 40px !important; /* Adjust height if needed */
  // color: #fff;//#3e465f;
  border-radius: 5px;
  border: 1px solid #3e465f;
  text-align: center; /* Center the input text */
  font-size: 18px; /* Increase font size for better visibility */
}
 
.dark-theme .otp-input{
  color: #fff;
}
 
.otp-separator {
  margin: 15px !important;
}

.bellcount{
  left: 37px !important;
  top: 7px !important;
  right: -14px !important;
}
.bellcount{
  left: 37px !important;
  top: 7px !important;
  right: -14px !important;
}
  
i.mdi.mdi-email-lock {
  font-size: 20px;
  color: red;
  border: 1px solid #000;
  padding: 2px 7px;
  border-radius: 50%;
}
.notificationbar-size table td .MuiCardContent-root{
  padding: 12px !important;
}
.dashboard-model table thead th{
  color: #ffffff !important;
}
.dashboard-model table{
  margin-bottom: 0px !important;
}
.dashboard-model .table>:not(:last-child)>:last-child>* {
  border-bottom-color: transparent !important;
}
.report-title .form-control{
  width:330px;
  margin-left: 0px;
  padding-left: 0px;
  margin-right: 20px;
}

.age-range-top-box .MuiFormControl-fullWidth{
  width: 230px;
  margin-right: 20px;
 
}
.light-theme .MuiOutlinedInput-root{
background: #ffffff !important;}
.age-range-top-box .MuiFormControl-fullWidth .light-theme{
  background-color: #fff !important;
}
.dark-theme .age-range-top-box .MuiFormControl-fullWidth .light-theme{
  background-color: #fff !important;
}
.age-range-top-box .form-control{
  width: 230px !important;
  margin-left: 0px !important;
  margin-right: 20px !important;
} 

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.1; } /* Instead of disappearing, it fades slightly */
  100% { opacity: 1; }
}

.blink {
  // animation: blink 1s infinite ease-in-out;
  animation: blink 1s steps(1, end) infinite;
} 
.user-card-box .user-box .hospital-box { justify-content: space-between; }
.user-card-box .user-box .hospital-box div { display: flex; align-items: center; gap: 8px; }
.user-card-box .user-box .hospital-box .user-active-box .user-active { border: 1px solid transparent; padding: 6px; background: #ddead6; border-radius: 8px; display: flex; height: max-content; }
// .user-card-box .user-box .hospital-box .user-active-box .user-active svg path { fill: #389a09; }
.user-card-box .user-box .hospital-box .user-active-box svg { width: 18px !important; height: 18px !important; }
.user-card-box .user-box .hospital-box .user-active-box .user-active:hover { border: 1px solid #389a09; background: transparent; }
.user-card-box .user-box .hospital-box .user-active-box .user-deactive { border: 1px solid transparent; padding: 6px; background: #f7deea; border-radius: 8px; display: flex; height: max-content; }
// .user-card-box .user-box .hospital-box .user-active-box .user-deactive svg path, .user-card-box .user-box .hospital-box .user-active-box .user-deactive svg { fill: #ca2374 !important; }
.user-card-box .user-box .hospital-box .user-active-box .user-deactive:hover { border: 1px solid #ca2374; background: transparent; }

.user-list-view-section .table-responsive .hospital-box { justify-content: space-between; }
.user-list-view-section .table-responsive .hospital-box div { display: flex; align-items: center; gap: 8px; }
.user-list-view-section .table-responsive .hospital-box .user-active-box .user-active { border: 1px solid transparent; padding: 6px; background: #ddead6; border-radius: 8px; display: flex; height: max-content; }
// .user-list-view-section .table-responsive .hospital-box .user-active-box .user-active svg path { fill: #389a09; }
.user-list-view-section .table-responsive .hospital-box .user-active-box svg { width: 18px !important; height: 18px !important; }
.user-list-view-section .table-responsive .hospital-box .user-active-box .user-active:hover { border: 1px solid #389a09; background: transparent; }
.user-list-view-section .table-responsive .hospital-box .user-active-box .user-deactive { border: 1px solid transparent; padding: 6px; background: #f7deea; border-radius: 8px; display: flex; height: max-content; }
// .user-list-view-section .table-responsive .hospital-box .user-active-box .user-deactive svg path, .user-card-box .user-box .hospital-box .user-active-box .user-deactive svg { fill: #ca2374 !important; }
.user-list-view-section .table-responsive .hospital-box .user-active-box .user-deactive:hover { border: 1px solid #ca2374; background: transparent; }

.box-inline{display: inline-block; vertical-align: top; width: 33%;}
.color-blue{color: #0134cf;}